
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.layout-banner-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: -16px;

    @at-root .accent-block & {
      margin-bottom: -20px;
      @include for-size(phone-portrait-down) {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.1), inset 0 -1px 0px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    position: relative;
    margin-top: 16px;

    &:not(:last-child) {
      margin-right: 16px;
      @include for-size(tablet-portrait-down) {
        margin-right: 8px;
      }
    }

    .esmp-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    margin-left: 16px;
    margin-top: 16px;

    .esmp-button {
      color: $color-white;
    }

    svg {
      border: 2px solid $color-white;
      border-radius: 50%;
      transition: border-color $base-animation-time;
    }

    @include hover(true) {
      .esmp-button {
        color: $primary-color-hover;
      }
      svg {
        border-color: $primary-color-hover;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 12px auto 0;
    cursor: pointer;

    .esmp-svg-icon {
      height: 20px;
      width: 20px;
      margin-left: 6px;
    }

    &--open {
      margin-top: 26px;
      .esmp-svg-icon {
        transform: scale(1, -1);
      }
    }
  }
}
